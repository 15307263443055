<script lang="ts">
  import "../app.css"

  import posthog from "posthog-js"
  import { browser } from "$app/environment"
  import { onMount } from "svelte"
  import { page, navigating } from "$app/stores"
  import { expoOut } from "svelte/easing"
  import { slide } from "svelte/transition"
  import { Toaster } from "svelte-sonner"
  interface Props {
    children?: import("svelte").Snippet
  }

  let { children }: Props = $props()

  const useDefaultMeta =
    $page?.route?.id?.includes("marketing") ||
    $page?.route?.id?.includes("admin")

  onMount(() => {
    if (browser) {
      posthog.init("phc_k0ZdGCKgnPX4dBlJOBTgzZdjSSJDpyS78tQ9t0sez59", {
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      })
    }
  })
</script>

<svelte:head>
  {#if useDefaultMeta}
    <title>Promo Amp - Run Engaging Giveaways, Sweepstakes & Competitions</title
    >
    <meta
      property="og:description"
      content="Promo Amp is the ultimate tool for running giveaways, sweepstakes, and competitions that drive real value for your business. Join thousands of brands leveraging our platform to create viral campaigns."
    />
    <meta property="og:image" content="https://img.promoamp.com/og_image.png" />
  {/if}
</svelte:head>

{#if $navigating}
  <!-- 
    Loading animation for next page since svelte doesn't show any indicator. 
     - delay 100ms because most page loads are instant, and we don't want to flash 
     - long 12s duration because we don't actually know how long it will take
     - exponential easing so fast loads (>100ms and <1s) still see enough progress,
       while slow networks see it moving for a full 12 seconds
  -->
  <div
    class="fixed w-full top-0 right-0 left-0 h-1 z-50 bg-primary"
    in:slide={{ delay: 50, duration: 12000, axis: "x", easing: expoOut }}
  ></div>
{/if}
<Toaster richColors />
{@render children?.()}
